<template>
  <div>
    <Header />
    <pageTitle txt="納品書" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-item" v-for="(day, key) in dayList" :key="key">
          <div
            class="list-item__data col-date"
            :class="{
              saturday: day.week == 6,
              holiday: day.week == 0 || day.is_holiday == 1
            }"
          >
            {{ day.format | changeDateObject | dateFormatShort }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/shipping/deliver/shop/',
                query: {
                  date: day.format
                }
              }"
            >
              <btnS class="edit" btnTxt="店舗選択" color="positive1" />
            </router-link>
            <div
              @click="
                (print = !print), (overlay = !overlay), setPrintDate(day.format)
              "
              class="print-btn"
            >
              <btnS class="print" btnTxt="全店分印刷" color="primary" />
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="pager-wrap">
        <pager />
      </div> -->
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div id="print" v-if="print">
        <div class="print-paper" v-for="(shop, key) in shops" :key="key">
          <div id="print-inner">
            <div id="print-meta">
              <p class="print-ttl">納品書</p>

              <p class="print-period">{{ printDate }}</p>
              <div class="print-time">
                {{ today }} 現在
                <br />
                1/1頁
                <br />
                <br />
                有限会社イセヤ
              </div>
              <p class="print-address">
                〒{{ shop.zipcode }}<br />
                {{ shop.address }}<br />
                {{ shop.store_name }}
                御中
              </p>
            </div>
            <ul class="list">
              <li class="list-head">
                <div class="list-head__item col-name">商品名</div>
                <div class="list-head__item col-data-1">数量</div>
                <div class="list-head__item col-data-1">単位</div>
                <div class="list-head__item col-data-1">税区分</div>
                <div class="list-head__item col-data-1">単価</div>
                <div class="list-head__item col-data-2">税抜金額</div>
                <div class="list-head__item col-data-2">消費税</div>
                <div class="list-head__item col-data-2">税込金額</div>
                <div class="list-head__item col-note">備考</div>
              </li>

              <li
                class="list-item"
                v-for="product in shop.items"
                :key="product.id"
              >
                <div class="list-item__data col-name">{{ product.name }}</div>
                <div class="list-item__data col-data-1">
                  {{ product.total_num | comma }}
                </div>
                <div class="list-item__data col-data-1">
                  {{ product.unit }}
                </div>
                <div class="list-item__data col-data-1">
                  {{ product.taxClass }}
                </div>
                <div class="list-item__data col-data-1">
                  {{ product.price | comma }}
                </div>
                <div class="list-item__data col-data-2">
                  {{ product.total_price | comma }}
                </div>
                <div class="list-item__data col-data-2">
                  {{ product.tax_price | comma }}
                </div>
                <div class="list-item__data col-data-2">
                  {{ product.total_tax_price | comma }}
                </div>
                <div class="list-item__data col-note"></div>
              </li>

              <li class="list-item total">
                <div class="list-item__data col-name">合計</div>
                <div class="list-item__data col-data-1"></div>
                <div class="list-item__data col-data-1"></div>
                <div class="list-item__data col-data-1"></div>
                <div class="list-item__data col-data-1"></div>
                <div class="list-item__data col-data-2">
                  {{ shop.total_price | comma }}
                </div>
                <div class="list-item__data col-data-2">
                  {{ shop.tax_price | comma }}
                </div>
                <div class="list-item__data col-data-2">
                  {{ shop.total_tax_price | comma }}
                </div>
                <div class="list-item__data col-note"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="fixedMenu" v-if="print">
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div v-on:click="(print = !print), (overlay = !overlay)">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.print-btn {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 751px;
  margin: 0 auto 20px;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}

.col-date {
  width: 150px;
}

.col-btn {
  width: 600px;
  justify-content: flex-end;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  overflow: scroll;
  padding-bottom: 120px;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

/*
#print {
  width: 668px;
  height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1.0rem;
  }
  .col-name {
    width: 155px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-note {
    width: 90px;
  }
}
*/

.print-paper {
  width: 668px;
  height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  margin: 30px auto 30px;
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
  }
  .list-head,
  .list-item {
    font-size: 1rem;
    height: 30px;
  }
  .list-head__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #dfdfdf;
    border-top: solid 1px #dfdfdf;
    box-sizing: border-box;
  }
  .col-name {
    width: 170px;
  }
  .col-data-1 {
    width: 38px;
  }

  .col-data-2 {
    width: 60px;
  }

  .col-note {
    width: 90px;
    font-size: 0.8rem;
    line-height: 1.25;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }
  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;

    .list {
      transform: scale(1);
    }
  }
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
// import pager from "@/components/common/pager";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL
    // pager
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      printDate: "2022年",
      weekday: "",
      dayList: [],
      shops: []
    };
  },
  async created() {
    const param = {};
    const api = new ApiClient();
    const result = await api.post("/iseya/transfer/date", param);
    this.dayList = result.list;
  },
  // mounted() {
  //   let getDate = new Date();
  //   let y = getDate.getFullYear();
  //   let m = getDate.getMonth() + 1;
  //   let d = getDate.getDate();
  //   this.today = y + "年" + m + "月" + d + "日";
  //   console.log('asdf')
  //   console.log(this.today)
  //   this.$store.dispatch('getShops');
  //   this.$store.dispatch('getProducts')
  // },
  methods: {
    async setPrintDate(date) {
      this.printDate = date;
      const param = {
        date: this.printDate
      };
      const api = new ApiClient();
      const result = await api.post("/iseya/delivery/store", param);
      if (result.list.length == 0) {
        alert("注文が登録されていません。");
        return;
      }
      this.shops = result.list;
      console.log(this.shops);
    }
  }
};
</script>
